import { format } from 'date-fns';

import { ReportsApiService } from '../_services';

const generateInitialDates = () => {
  const today = new Date();
  const yesterday = new Date(today.setDate(today.getDate() - 7));

  return [
    format(yesterday, 'yyyy-MM-dd'),
    format(new Date(), 'yyyy-MM-dd'),
  ];
};

export const generateReportsTypes = scheme => scheme.map(({ type }) => type);

export const generateReportsConfig = scheme => scheme
  .reduce((acc, { type, params, description }) => {
    acc[type] = {
      action: data => ReportsApiService.generateReport(({ type, data })),

      createForm: () => params.reduce((formAcc, param) => {
        if (param.type === 'daterange') {
          formAcc.dates = generateInitialDates();

          return formAcc;
        }

        formAcc[param.name || param.type] = null;

        return formAcc;
      }, {}),

      fields: params.map(param => ({
        type: param.type,
        name: param.name,
        label: param.displayName || param.name,
      })),

      parseForm: data => Object.keys(data).reduce((formAcc, dataKey) => {
        if (dataKey === 'dates') {
          formAcc.from = `${data.dates[0]}T00:00:00.000Z`;
          formAcc.to = `${data.dates[1]}T23:59:59.000Z`;

          return formAcc;
        }

        formAcc[dataKey] = data[dataKey];

        return formAcc;
      }, {}),

      description,
      type,
    };

    return acc;
  }, {});
