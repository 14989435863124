import HTTP from '@api';

import { generateXHRError } from '@helpers';

import { createComplaint } from '../_meta/complaint';

export const RefundsApiService = {
  async getComplaints({
    status = null, reference = null,
    printCustomerId = null, printProviderId = null,
    pageNum = 1, pageSize = 10,
  }) {
    try {
      const { data } = await HTTP.get('complaints', {
        params: {
          status, reference, printCustomerId, printProviderId, pageNum, pageSize,
        },
      });

      return Promise.resolve(data || []);
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async getComplaint({ id }) {
    try {
      const { data } = await HTTP.get(`complaints/${id}`);

      return data || createComplaint();
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async reviewComplaint({ id, data }) {
    try {
      return await HTTP.post(`complaints/review/${id}`, data);
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};

export default {};
