export const createComplaint = () => ({
  orderId: 0,
  orderItemId: 0,
  printCustomerId: 0,
  printProviderId: 0,
  status: 0,
  reason: '',
  created: '',
  compensateProduction: true,
  compansateShipping: true,
  id: 0,
  history: [],
});

export default {};
