const IN_PROGRESS_REPORTS_KEY = 'IN_PROGRESS_REPORTS';

const getIds = () => JSON.parse(window.localStorage.getItem(IN_PROGRESS_REPORTS_KEY) || '[]');

export const ReportsLocalStorageService = {
  getReportsIdsInProgress() {
    return getIds();
  },

  addReportIdToInProgress(reportId) {
    const ids = getIds();

    if (!ids.includes(reportId)) {
      ids.push(reportId);
      window.localStorage.setItem(IN_PROGRESS_REPORTS_KEY, JSON.stringify(ids));
    }

    return ids;
  },

  isReportIdInProgress(reportId) {
    const ids = getIds();

    return ids.includes(reportId);
  },

  removeReportIdFromInProgress(reportId) {
    const ids = getIds();

    if (ids.includes(reportId)) {
      ids.splice(ids.indexOf(reportId), 1);
      window.localStorage.setItem(IN_PROGRESS_REPORTS_KEY, JSON.stringify(ids));
    }

    return ids;
  },
};

export default {};
