import HTTP from '@api';

import { generateXHRError } from '@helpers';

import { isFinishedReport } from '../_helpers';

export const ReportsApiService = {
  async getReportsHistory({
    type = null, dates = null, pageNum = 1, pageSize = 10,
  }) {
    try {
      const { data } = await HTTP.get('reports/history', {
        params: {
          startDate: dates && dates.length ? dates[0] : null,
          endDate: dates && dates.length ? `${dates[1]} 23:59:59` : null,
          type,
          pageNum,
          pageSize,
        },
      });

      return data || [];
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async getInProgressReports() {
    try {
      const { data } = await HTTP.get('reports/current');

      return (data || []).filter(({ status }) => !isFinishedReport(status));
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async checkReportGenerationStatus(reportId) {
    try {
      return await HTTP.get(`reports/${reportId}/status`);
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async cancelReportGeneration(reportId) {
    try {
      return await HTTP.post(`reports/${reportId}/cancel`);
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async getReportsScheme() {
    try {
      const { data } = await HTTP.get('reports');

      return data || [];
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async generateReport({ type, data }) {
    try {
      const { data: response } = await HTTP.post(`reports/type/${type}`, data);

      return response || {};
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};
