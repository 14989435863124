import { ReportsApiService } from './reports.api.service';

import { ReportsLocalStorageService } from './reports.localStorage.service';

import { isFinishedReport } from '../_helpers';

export const ReportsStatusService = {
  async refreshIncludingReportsIdsInQueue() {
    try {
      const data = await ReportsApiService.getInProgressReports();

      data.forEach(({ reportId }) => ReportsLocalStorageService.addReportIdToInProgress(reportId));

      return ReportsLocalStorageService.getReportsIdsInProgress();
    } catch (e) {
      console.error(`Can't get actual in progress reports. [!]REASON: ${e}`); // eslint-disable-line

      return ReportsLocalStorageService.getReportsIdsInProgress();
    }
  },

  async checkReportsGenerationStatuses(cb) {
    const ids = ReportsLocalStorageService.getReportsIdsInProgress();

    const checks = await Promise.all(ids.map(id => (async () => {
      try {
        const { data } = await ReportsApiService.checkReportGenerationStatus(id);

        return { id, finished: isFinishedReport(data.status), status: (data.status || '').toLowerCase() };
      } catch (e) {
        console.error(`Can't check actual status of ${id} in progress reports. [!]REASON: ${e}`); // eslint-disable-line

        return { id, finished: false, status: '' };
      }
    })()));

    checks.forEach((check) => {
      if (check.finished) {
        ReportsLocalStorageService.removeReportIdFromInProgress(check.id);

        if (check.status === 'finished') {
          cb(check.id);
        }
      }
    });
  },
};

export default {};
