export default {
  setOrders(state, payload) {
    state.orders = payload;
  },
  setOrder(state, payload) {
    state.order = payload;
  },
  setStatistics(state, payload) {
    state.statistics = payload;
  },
  setTrackingInfo(state, payload) {
    state.trackingInfo = payload;
  },
  setOrderItemHistory(state, payload) {
    state.orderItemHistory = payload;
  },
};
