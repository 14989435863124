import { RefundsApiService } from './_services/refunds.api.service';

import { createComplaint } from './_meta/complaint';

export default {
  namespaced: true,

  state: {
    complaints: [],
    complaint: createComplaint(),
  },

  mutations: {
    setComplaints(state, payload) {
      state.complaints = payload;
    },

    setComplaint(state, payload) {
      state.complaint = payload;
    },
  },

  actions: {
    async getComplaints({ commit }, params) {
      commit('setComplaints', await RefundsApiService.getComplaints({ ...params }));
    },

    async getComplaint({ commit }, params) {
      commit('setComplaint', await RefundsApiService.getComplaint({ ...params }));
    },
  },
};
