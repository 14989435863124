const generateOrderInformation = ({
  order: {
    item: { id, invoice } = {},
    itemDetail: {
      orderDate, parentOrderId, deliveryDate,
      contractType, shipService, shipMethod,
      zipCode, shippingFee,
    } = {},
    orderItems = [],
    messages = [],
    printCustomer,
    lowerPrices,
  } = {},
}) => ({
  id,
  invoice,
  orderDate,
  parentOrderId,
  deliveryDate,
  contractType,
  shipService,
  shipMethod,
  zipCode,
  orderItemsCount: orderItems.length,
  messagesItemsCount: messages.length,
  status: messages.length ? messages[0].messageType : '',
  printCustomer,
  lowerPrices,
  shippingFee,
});

const generateOrderItems = (
  {
    order: {
      orderItems = [], orderItemsStatuses = [], shipCosts = [], routeResults = [],
    },
  },
  {
    orderPrices: [orderPrice = {}],
  },
) => {
  const result = [];

  orderItems.forEach(({
    id, productType, mailing, quantity, generatedId, printGroupId, directLink,
  }) => {
    const {
      shipCostId, routeResultId, state, subState,
      printProviderId, priceRouteResultId, trackingNumber,
    } = orderItemsStatuses
      .find(({ orderItemId }) => orderItemId === id) || {};
    const { cost = '' } = shipCosts.find(s => s.id === shipCostId) || {};
    const { routeRule } = routeResults.find(r => r.id === routeResultId) || {};
    const { items: priceItems = [] } = orderPrice;

    result.push({
      id,
      productType,
      mailing,
      quantity,
      generatedId,
      printGroupId,
      cost,
      state,
      subState,
      printProviderId,
      priceRouteResultId,
      routeResultId,
      trackingNumber,
      routeRule,
      directLink,
      success: priceItems.length === orderItems.length
        ? priceItems[0].accountId === printProviderId
        : true,
    });
  });

  return result;
};

/**
 * Generate prices by irssPriceHistory, pdsPriceHistory and orderItems
 *
 * Choose the first item in irssPriceHistory and jump in prices,
 * prices it's our prices data. Sort by totalCost by increase.
 * In prices choose items, from this item will generate follow fields
 *  weights - array by pdsPriceHistory (array of printing prices),
 *  filtered by account and current order item id,
 *  choose every filtered order item weight,
 *    after calculate total weight
 *  printCosts - filter similarly weights for every filtered order
 *    item price, not total
 *  totalCost - sum of printCosts and shipCost (item.cost)
 */
const generateOrderPrices = ({
  order: { irssPriceHistory = [], pdsPriceHistory = [], currencyRateHistory = {} } = {},
}) => {
  const [priceHistory = { prices: [] }] = irssPriceHistory;
  const { prices } = priceHistory;
  const result = [];

  prices.forEach(({ items = [], pickupDate }) => {
    const priceResult = [];
    items.forEach(({
      orderItems = [],
      ppId,
      cost: shipCost,
      currencyCode: shipCostCurrencyCode,
      shippingVendor,
      serviceName,
    }, i) => {
      const currencyRateHistoryRates = currencyRateHistory?.rates || [];
      const accumulateValue = value => orderItems
        .reduce((acc, id) => {
          const { [value]: val } = pdsPriceHistory.find(({ accountId, orderItemId }) => (
            accountId === ppId && orderItemId === id
          )) || {};
          return acc + (val || 0);
        }, 0)
        .toFixed(3);

      const printCosts = orderItems.map((id) => {
        const { cost, currencyCode } = pdsPriceHistory.find(({ accountId, orderItemId }) => (
          accountId === ppId && orderItemId === id
        )) || {};
        const { rate = 1, provider, timestamp } = currencyRateHistoryRates.find(({
          baseCurrencyCode, currencyCode: cc,
        }) => (
          baseCurrencyCode === currencyRateHistory?.baseCurrencyCode
            && cc === currencyCode
        )) || {};
        return {
          cost, currencyCode, rate, provider, timestamp,
        } || {};
      });

      const shipCostCurrency = currencyRateHistoryRates.find(({
        baseCurrencyCode, currencyCode: cc,
      }) => (
        baseCurrencyCode === currencyRateHistory?.baseCurrencyCode
        && cc === shipCostCurrencyCode
      )) || {};

      const totalCost = Number(accumulateValue('cost')) + shipCost;
      const totalWeight = Number(accumulateValue('weight'));

      priceResult.push({
        accountId: ppId,
        orderItems,
        totalWeight,
        totalCost,
        printCosts,
        shipCost,
        shipCostCurrency: { currencyCode: shipCostCurrencyCode, ...shipCostCurrency },
        totalCostCurrency: currencyRateHistoryRates.length
          ? currencyRateHistoryRates[0]
          : { currencyCode: currencyRateHistory?.baseCurrencyCode || '' },
        currentCurrencyCode: currencyRateHistory?.baseCurrencyCode || '',
        shipType: `${shippingVendor || ''} ${serviceName || ''}`.trim(),
        pickupDate: i === 0 ? pickupDate : null,
      });
    });
    result.push({
      items: priceResult,
      totalCost: Number(priceResult.reduce((acc, { totalCost }) => acc + totalCost, 0).toFixed(3)),
    });
  });

  return result
    .sort((a, b) => a.totalCost - b.totalCost)
    .map((r, i) => ({
      items: r.items.map(item => ({ ...item, marked: i === 0 })),
      totalCost: r.totalCost,
    }));
};

const generateOrderRouteResults = ({ order: { routeResults = [] } }) => routeResults
  .sort((a, b) => {
    const [date1, date2] = [new Date(a.dateCreated), new Date(b.dateCreated)];
    if (date1 < date2) {
      return -1;
    }
    if (date1 > date2) {
      return 1;
    }
    return 0;
  });

const generateOrderMessages = ({
  order: { messages = [], clientRequests = [], sendErrors = [] },
}) => messages
  .map((msg) => {
    const getError = (id) => {
      const { comments: error = false } = sendErrors
        .find(({
          printClientRequestId,
        }) => clientRequests.some(() => id === printClientRequestId)) || {};
      return error;
    };
    return {
      ...msg,
      clientRequests: clientRequests
        .filter(({ messageId }) => messageId === msg.id)
        .map(req => ({
          ...req,
          dateCreated: msg.dateCreated,
          error: getError(req.id),
        })),
    };
  })
  .sort((a, b) => {
    const [date1, date2] = [new Date(a.dateCreated), new Date(b.dateCreated)];
    if (date1 < date2) {
      return -1;
    }
    if (date1 > date2) {
      return 1;
    }
    return 0;
  });

export default {
  orderInfo: generateOrderInformation,
  orderItems: generateOrderItems,
  orderRouteResults: generateOrderRouteResults,
  orderPrices: generateOrderPrices,
  orderMessages: generateOrderMessages,
};
