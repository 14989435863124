import { saveAs } from 'file-saver';

export const downloadExcelWithDisposition = async ({ headers, data }) => {
  const disposition = headers['content-disposition'];
  let filename;
  if (disposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  saveAs(new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  }), filename);
  return Promise.resolve();
};

export default {};
